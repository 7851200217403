import * as React from "react";
import { AboutSecondFoldContainer, SecondFoldItemContainer } from "../styles";
import { AboutSecondFoldProps, SecondFoldContentItem } from "../types";
import { Box, Button } from "@mui/material";
import Link from "components/common/link";
import { PrismicRichText } from "@prismicio/react";

const AboutSecondFold: React.FC<AboutSecondFoldProps> = ({
  title,
  footerParagraph,
  itemList,
}) => {
  return (
    <AboutSecondFoldContainer className="about-second-fold-container">
      <Box className="second-fold-title">
        <PrismicRichText field={title} />
      </Box>

      <Box className="second-fold-list-container">
        {itemList.map((item: SecondFoldContentItem, index: number) => {
          return (
            <SecondFoldItemContainer
              className="second-fold-item-container"
              key={index}
            >
              <PrismicRichText field={item.item_content_paragraph.richText} />
            </SecondFoldItemContainer>
          );
        })}
      </Box>

      <Box className="second-fold-careers">
        <PrismicRichText field={footerParagraph} />
        <Button
          component={Link}
          className="second-fold-careers-link"
          to="/careers"
          variant="contained"
        >
          Join The Team
        </Button>
      </Box>
    </AboutSecondFoldContainer>
  );
};

export default AboutSecondFold;
