import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const AboutTopContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="section" {...props} />
))`
  &.about-top-container {
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(22, 0, 9)};
    margin: 0 auto;
    max-width: 960px;

    & > .top-container-title {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 3)};

      & > h1 {
        color: ${({ theme }) => theme.palette.primary.main};
        font-family: "utopia-std-display";
        font-size: 80px;
        font-weight: 400;
        text-align: center;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          font-size: 52px;
        }
      }
    }

    & > .cover-image {
      margin: ${({ theme: { spacing } }: ThemeType) => spacing(3, 0, 10)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 3)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        padding: 0;
      }
    }

    & > .secondary-container {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 3)};

      & > .secondary-container-item {
        line-height: 1.695;
        padding-right: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
        text-align: left;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          line-height: 1.635;
          padding-right: 0;
          text-align: center;
        }

        & + .secondary-container-item {
          padding-right: 0;
          padding-left: ${({ theme: { spacing } }: ThemeType) => spacing(4)};

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(4)};
            padding-left: 0;
          }
        }

        & > h2 {
          font-size: 24px;
          font-weight: 400;

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            font-size: 16px;
            font-weight: 600;
          }
        }

        & > p {
          color: #000;
          font-size: 16px;
          margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(2)};

          ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export const AboutSecondFoldContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="section" {...props} />
)`
  &.about-second-fold-container {
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(9, 0, 22)};
    margin: 0 auto;
    max-width: 960px;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(9, 0)};
    }

    & > .second-fold-title {
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 3)};

      & > h2 {
        font-family: "utopia-std-display";
        font-weight: 400;
        font-size: 50px;
        line-height: 1.185;
        text-align: center;

        ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
          font-size: 36px;
        }
      }
    }

    & > .second-fold-list-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: ${({ theme: { spacing } }: ThemeType) => spacing(8, -4, 0)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 3)};

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        margin: ${({ theme: { spacing } }: ThemeType) => spacing(4, 0, 0)};
      }

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        display: block;
        margin: ${({ theme: { spacing } }: ThemeType) => spacing(8, 0, 0)};
      }
    }

    & > .second-fold-careers {
      color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
      font-size: 14px;
      margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(9)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 3)};
      text-align: center;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        display: none;
      }

      & > .second-fold-careers-link {
        display: inline-block;
        font-size: 18px;
        letter-spacing: 0.01em;
        margin-top: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
        padding-right: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
        padding-left: ${({ theme: { spacing } }: ThemeType) => spacing(6)};
        text-transform: none;
      }
    }
  }
`;

export const SecondFoldItemContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.second-fold-item-container {
    background-color: white;
    border-radius: 4px;
    color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
    margin: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(6, 2)};
    text-align: center;
    width: 300px;

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      margin: ${({ theme: { spacing } }: ThemeType) => spacing(1)};
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(3, 1)};
      width: auto;
    }

    & > h3 {
      font-family: "utopia-std-display";
      font-size: 24px;
      font-weight: 400;
      line-height: 1.3374;

      ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
        font-size: 18px;
      }
    }
  }
`;
