import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const AboutPageContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.about-page-container {
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_pink.background};
  }
`;
