import * as React from "react";
import { AboutTopContainer } from "../styles";
import { AboutTopProps } from "../types";
import { GatsbyImage } from "gatsby-plugin-image";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { PrismicRichText } from "@prismicio/react";

const AboutTop: React.FC<AboutTopProps> = ({
  title,
  secondaryTitle,
  secondaryParagraph,
  missionTitle,
  missionParagraph,
  image,
}) => {
  return (
    <AboutTopContainer className="about-top-container">
      <Box className="top-container-title">
        <PrismicRichText field={title} />
      </Box>

      <Box className="cover-image">
        <GatsbyImage image={image} alt="cover image" />
      </Box>

      <Grid container className="secondary-container">
        <Grid item className="secondary-container-item" md={6}>
          <PrismicRichText field={secondaryTitle} />
          <PrismicRichText field={secondaryParagraph} />
        </Grid>

        <Grid item className="secondary-container-item" md={6}>
          <PrismicRichText field={missionTitle} />
          <PrismicRichText field={missionParagraph} />
        </Grid>
      </Grid>
    </AboutTopContainer>
  );
};

export default AboutTop;
