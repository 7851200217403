import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "components/common/seo";
import { AboutPageContainer } from "./styles";
import { AboutProps } from "./types";
import AboutTop from "./components/top";
import AboutSecondFold from "./components/second-fold";

const About: React.FC<AboutProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicAboutPage {
        nodes {
          data {
            title {
              richText
            }
            secondary_title {
              richText
            }
            content_paragraph {
              richText
            }
            mission_title {
              richText
            }
            mission_paragraph {
              richText
            }
            cover_image {
              gatsbyImageData
            }
            body {
              ... on PrismicAboutPageDataBodyAboutSecondFold {
                id
                primary {
                  second_fold_title {
                    richText
                  }
                  footer_paragraph {
                    richText
                  }
                }
                items {
                  item_content_paragraph {
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  return (
    <>
      <Seo />

      <AboutPageContainer className="about-page-container">
        <AboutTop
          title={document.title.richText}
          secondaryTitle={document.secondary_title.richText}
          secondaryParagraph={document.content_paragraph.richText}
          missionTitle={document.mission_title.richText}
          missionParagraph={document.mission_paragraph.richText}
          image={document.cover_image.gatsbyImageData}
        />

        <AboutSecondFold
          title={document.body[0].primary.second_fold_title.richText}
          footerParagraph={document.body[0].primary.footer_paragraph.richText}
          itemList={document.body[0].items}
        />
      </AboutPageContainer>
    </>
  );
};

export default About;
